import React, {useState, useEffect} from "react";
import { navigate } from "@reach/router"
import { Form } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby"

import SearchResultInputBox from 'gatsby-theme-starberry-lomondgroup/src/components/SearchResult/SearchResultBox';
import {GetPrice, PropertySearchUrl, searchStateToUrl} from "gatsby-theme-starberry-lomondgroup/src/common/utils/property_utils";
import {defaultArea_Slug, index_name, search_box_placeholder} from "gatsby-theme-starberry-lomondgroup/src/common/constant";

const BannerSearch = (props) => {

    var menu_slug = props.GQLPage?.menu?.slug;

    var formFields = {
        query: '',
        range: {
            price: {
                min: null,
                max: null
            },
            bedroom: {
                min: null
            }
        },
        menu: {
            building: null
        }
    }


    const[search_type_val, setSearchType] = useState("lettings");
    const[min_price_val, setMinPrice] = useState(0);
    const[max_price_val, setMaxPrice] = useState(0);
    const[bed_val, setBedroom] = useState('');
    const[query, setQuery] = useState("");
    const[property_type_val, setPropertyType] = useState("");
    const[department, setDepartment] = useState("residential");
    const[price_list, setPriceList] = useState([]);

    const data = useStaticQuery(graphql`
        query BannerSearchBV{
            glstrapi {
                propertySearch {
                    search_type {
                        name
                        value
                        id
                    }
                    sales_price_list {
                      price:Price
                    }
                    rent_price_list {
                      price
                    }
                }
            }
        }
    `)

    //console.log("data ==>", data)
    var search_type_list = data?.glstrapi.propertySearch.search_type;
    var sales_price_list = data?.glstrapi.propertySearch.sales_price_list;
    var rent_price_list = data?.glstrapi.propertySearch.rent_price_list;
    //var price_list = search_type_val === "lettings" ?  sales_price_list : rent_price_list;

    useEffect(()=>{
        //console.log("menu_slug", menu_slug);
        if(menu_slug?.includes("buy") || menu_slug?.includes("sell")){
            setSearchType("sales")
        } else if(menu_slug?.includes("rent") || menu_slug?.includes("landlords")){
            setSearchType("lettings")
        } else if(menu_slug?.includes("student-hub")){
            setSearchType("students")
        } else{
            if(search_type_list.length > 0){
                setSearchType(search_type_list[0].value)
            }
        }
        
    },[search_type_list, menu_slug])

    useEffect(()=>{
        //console.log("search_type_val", search_type_val);

        if(search_type_val === "lettings"){
            setPriceList(rent_price_list)
        } else{
            setPriceList(sales_price_list)
        }
        
    },[search_type_val])


    /* ========= search types ======= */
    var search_type_options = [];
    search_type_list.map((item, index) => {
        if(menu_slug === "home"){
            // if(item.value === "sales" || item.value === "lettings"){
            //     search_type_options.push({ label: item.name, value: item.value })
            // }
            search_type_options.push({ label: item.name, value: item.value })
        } else{
            search_type_options.push({ label: item.name, value: item.value })
        }
    })

    //console.log("price_list", price_list, search_type_val, sales_price_list, rent_price_list)


    // /* ==== Min Price === */
    // var min_price = [{ value: '', label: 'Min Price' }];
    // price_list.map((item, index) => {
    //     min_price.push({ value: parseInt(item.price), label: search_type_val === "sales" ? GetPrice(parseInt(item.price)) : GetPrice(parseInt(item.price))+" pcm" })
    // })

    // /* ==== Max Price === */
    // var max_price = [{ value: '', label: 'Max Price' }];
    // price_list.map((item, index) => {
    //     max_price.push({ value: parseInt(item.price), label: search_type_val === "sales" ? GetPrice(parseInt(item.price)) : GetPrice(parseInt(item.price))+" pcm" })
    // })

    /* ==== Min Price === */
    var min_price = [{ value: '', label: 'Min Price' }];
    price_list.map((item, index) => {
        if (parseInt(max_price_val) > 0) {
            if (parseInt(item.price) < parseInt(max_price_val)) {
                min_price.push({ value: parseInt(item.price), label: search_type_val === "lettings" ? GetPrice(parseInt(item.price)) + " pcm" : GetPrice(parseInt(item.price))})
            }
        } else {
            min_price.push({ value: parseInt(item.price), label: search_type_val === "lettings" ? GetPrice(parseInt(item.price)) + " pcm" : GetPrice(parseInt(item.price)) })
        }

    })

    /* ==== Max Price === */
    var max_price = [{ value: '', label: 'Max Price' }];
    price_list.slice(0).map((item, index) => {
        if (parseInt(min_price_val) > 0) {
            if (parseInt(item.price) > parseInt(min_price_val)) {
                max_price.push({ value: parseInt(item.price), label: search_type_val === "lettings" ? GetPrice(parseInt(item.price)) + " pcm" : GetPrice(parseInt(item.price))  })
            }

        } else {
            max_price.push({ value: parseInt(item.price), label: search_type_val === "lettings" ?  GetPrice(parseInt(item.price)) + " pcm" : GetPrice(parseInt(item.price))  })
        }

    })
    

    /* ==== Bedrroms === */
    var bed = [{ value: '', label: 'Bedrooms' }, { value: 0, label: "Studio" }];
    for(var i = 1; i<=10; i++){
        bed.push({ value: i, label: i+"+" })
    }


    /* ======== Property Type ======= */
    // var filter_property_type = department === "residential" ? property_type_list.filter(item => item.department === "residential" || item.department === "both") : property_type_list.filter(item => item.department === "commercial" || item.department === "both");

    // var property_type = [{ value: '', label: 'Property Type' }];
    // filter_property_type.map((item, index) => {
    //     property_type.push({ value: item.value, label: item.label })
    // })

    const handleChange = (e) => {
        setQuery(e.target.value)
    }

    const onFilterSubmit = (searchState) => {
        
        var path = PropertySearchUrl(department, search_type_val)
        navigate(searchStateToUrl(index_name, searchState, path))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        formFields.query = query ? query :  defaultArea_Slug;
        formFields.range.price['min'] = min_price_val;
        formFields.range.price['max'] = max_price_val;
        formFields.range.bedroom['min'] = bed_val;
        formFields.menu['building'] = property_type_val;
        //formFields.includes = include;

        onFilterSubmit(formFields)
    }

    //console.log("defaultArea_Slug", defaultArea_Slug, defaultArea)

    console.log("filter ==>", department, search_type_val)

    return(
        <div className={`filter-box ${menu_slug === "student-hub" ? "rmv_brdr_rad" : ""}`}>
            <ul className="filter-tab">
                {search_type_options?.length > 0 && search_type_options.map(item => {

                    if(menu_slug === "student-hub"){
                        // if(item.value === "students"){
                        //     return(
                        //         <li className={`${item.value === search_type_val ? "actv" : ""}`} onClick={() => setSearchType(item.value)}>{item.label}</li>
                        //     )
                        // }
                    } else{
                        if(item.value === "sales" || item.value === "lettings"){
                            return(
                                <li className={`${item.value === search_type_val ? "actv" : ""}`} onClick={() => setSearchType(item.value)}>{item.label}</li>
                            )
                        }
                    }

                    
                })}                   
            </ul>

            <Form action="javascript:;" method="post" onSubmit={handleSubmit}>
                {/* <div className="select-option dropdown search_type first">
                    <SelectDropDown 
                        options={search_type_options} 
                        placeholder={search_type_val === "sales" ? "Buy" : "Rent" } 
                        className="react-select"
                        classNamePrefix="react-select"
                        handleChange={setSearchType}
                    />
                </div> */}

                <div className="input-box">
                    {/* <input
                        type="text"
                        placeholder="Property Location"
                        className="location_input" 
                        onChange={handleChange}
                    /> */}
                    <SearchResultInputBox areaVal={query} placeHolder={search_box_placeholder || `Enter a location e.g. Edinburgh or EH1`} department={department} handleChange={setQuery} className="location_input"/>
                </div>
                {/* <div className="select-option dropdown d-none d-lg-flex">
                    <SelectDropDown 
                        options={min_price} 
                        placeholder="Min Price" 
                        className="react-select"
                        classNamePrefix="react-select"
                        handleChange={(e) => setMinPrice(e.value)}
                        selectedValue={parseInt(min_price_val) > 0 ? GetPrice(min_price_val) : ""}
                    />
                </div>
                <div className="select-option dropdown d-none d-lg-flex">
                    <SelectDropDown 
                        options={max_price} 
                        placeholder="Max Price" 
                        className="react-select"
                        classNamePrefix="react-select"
                        handleChange={(e) => setMaxPrice(e.value)}
                        selectedValue={parseInt(max_price_val) > 0 ? GetPrice(max_price_val) : ""}
                    />
                </div>
                <div className="select-option dropdown d-none d-lg-flex bedroom">
                    <SelectDropDown 
                        options={bed} 
                        placeholder="Bedrooms" 
                        className="react-select"
                        classNamePrefix="react-select"
                        handleChange={(e) => setBedroom(e.value)}
                        selectedValue={parseInt(bed_val) === 0 ? "Studio" : parseInt(bed_val) > 0 ? bed_val+"+" : ""}
                    />
                </div> */}

                <button className="search-icon" type="submit">
                    <i className="icon-search"></i> <span className="d-flex">Search</span>
                </button>
            </Form>
        </div>
    )
}
export default BannerSearch;